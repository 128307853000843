<template>
    <div class="ibox-content" :class="noPadding ? 'no-padding' : ''">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "IboxContent",
      props: ['noPadding']
    }
</script>

<style scoped>
  .no-padding {
    padding: 0;
  }
</style>
